<template>
  <div>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center">
        <b-col cols="12" sm="4"
          ><div class="my-2"><logo /></div
        ></b-col>
      </b-row>
    </b-container>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center text-center">
        <b-col cols="12" sm="4"
          ><div class="my-1">
            <b-img
              fluid
              src="@core/assets/images/gameplay/live-casino.png"
            /></div
        ></b-col>
      </b-row>
      <b-row class="justify-content-sm-center text-center">
        <b-col cols="12" sm="4"
          ><div class="my-1">
            <b-img
              fluid
              src="@core/assets/images/gameplay/live-casino.png"
            /></div
        ></b-col>
      </b-row>
      <b-row class="justify-content-sm-center text-center">
        <b-col cols="12" sm="4"
          ><div class="my-1">
            <b-img
              fluid
              src="@core/assets/images/gameplay/live-casino.png"
            /></div
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer, BImg } from 'bootstrap-vue'

import Logo from './component/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BContainer,
    BImg
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
